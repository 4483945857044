<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" md="12" class="text-right">
        <Buttons
          :textCancel="$t('GENERAL.CANCEL')"
          v-on:cancel="btnCancel"
          :textSave="$t('GENERAL.SAVE')"
          v-on:save="saveData"
          :save_loading="save_loading"
          :btnSaveNew="false"
          :save_new_loading="save_new_loading"
          :textSaveNew="$t('GENERAL.SAVE_AND_NEW')"
          v-on:save-new="btnSaveNew"
          :btnDuplicate="isEditForm"
          :duplicate_loading="duplicate_loading"
          :textDuplicate="$t('GENERAL.DUPLICATE')"
          v-on:duplicate="duplicate"
          :btnDelete="isEditForm"
          :delete_loading="delete_loading"
          :textDelete="$t('GENERAL.DELETE')"
          v-on:delete="btnDelete"
        />
      </v-col>
    </v-row>
    <v-row gutters="2">
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title>
            <div>
              {{ $t("FEE.GENERAL_INFORMATION") }} <br />
              <i class="size-medimum">
                {{ $t("FEE.DESCRIPTION_GENERAL_INFORMATION") }}
              </i>
            </div>
          </v-card-title>
          <v-card-text>
            <FormFee
              @data="setFormData"
              :formData="formData"
              ref="formFee"
            ></FormFee>
          </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-title>
            {{ $t("FEE.PRICINGS") }}
          </v-card-title>
          <v-card-text>
            <FormPricing
              ref="feePricing"
              @data="setFormPricing"
              :pricing="formPricing"
            ></FormPricing>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-expand-transition>
          <v-card
            class="mb-2"
            v-show="formData.applicableTo === APPLICABLE_TO_COURSES"
          >
            <v-card-title>
              {{ $i18n.t("FEE.APPLICABLE_TO") }}
              {{ $i18n.t("PRODUCTS_ADDONS.COURSES") }}
            </v-card-title>
            <v-card-text>
              <ProductsTreeView
                :input.sync="formList.courses"
                :selection="formList.courses"
                :data="courses"
                keyId="productId"
                keyName="name"
              />
            </v-card-text>
          </v-card>
        </v-expand-transition>
        <v-expand-transition>
          <v-card
            class="mb-2"
            v-show="formData.applicableTo === APPLICABLE_TO_LODGING"
          >
            <v-card-title>
              {{ $i18n.t("FEE.APPLICABLE_TO") }}
              {{ $i18n.t("PRODUCTS_ADDONS.LODGING") }}
            </v-card-title>
            <v-card-text>
              <ProductsTreeView
                :input.sync="formList.rooms"
                :selection="formList.rooms"
                :data="rooms"
                keyId="productId"
                keyName="room_with_lodging"
              />
            </v-card-text>
          </v-card>
        </v-expand-transition>
        <v-expand-transition>
          <v-card
            class="mb-2"
            v-show="formData.applicableTo === APPLICABLE_TO_SERVICES"
          >
            <v-card-title>
              {{ $i18n.t("FEE.APPLICABLE_TO") }}
              {{ $i18n.t("PRODUCTS_ADDONS.ADDONS") }}
            </v-card-title>
            <v-card-text>
              <ProductsTreeView
                :input.sync="formList.addons"
                :selection="formList.addons"
                :data="addons"
                keyId="productId"
                keyName="name"
              />
            </v-card-text>
          </v-card>
        </v-expand-transition>
        <v-expand-transition>
          <v-card
            class="mb-2"
            v-show="formData.applicableTo === APPLICABLE_TO_PACKAGE"
          >
            <v-card-title>
              {{ $i18n.t("FEE.APPLICABLE_TO") }}
              {{ $i18n.t("PRODUCTS_ADDONS.PACKAGES") }}
            </v-card-title>
            <v-card-text>
              <ProductsTreeView
                :input.sync="formList.packages"
                :data="productPackages"
                :selection="formList.packages"
                keyId="productId"
                keyName="name"
              />
            </v-card-text>
          </v-card>
        </v-expand-transition>
        <v-expand-transition>
          <v-card class="mb-2" v-show="formData.applicableTo === ''">
            <v-card-title>{{
              $i18n.t("FEE.PLEASE_APPLICABLE_TO")
            }}</v-card-title>
          </v-card>
        </v-expand-transition>
      </v-col>
    </v-row>
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="
              deleteData();
              isShowDialogDelete = false;
            "
          >
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import FormFee from "@/view/content/components/fees/Form";
import FormPricing from "@/view/content/components/fees/FormPricing";
import ProductsTreeView from "@/view/content/components/ProductsTreeView";
import Buttons from "@/view/content/components/Buttons";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "fees",
  components: { FormFee, FormPricing, ProductsTreeView, Buttons },
  data() {
    return {
      isShowDialogDelete: false,
      id: null,
      formList: {
        courses: [],
        rooms: [],
        addons: [],
        packages: [],
      },
      formData: {
        name: "",
        feeTypeId: "",
        applicableTo: "",
        description: "",
      },
      formPricing: [],
      APPLICABLE_TO_COURSES: 1,
      APPLICABLE_TO_LODGING: 2,
      APPLICABLE_TO_SERVICES: 3,
      APPLICABLE_TO_PACKAGE: 6,
    };
  },
  async created() {
    await this.getListFees();
    await this.getCourses();
    await this.getRooms();
    await this.getAddons();
    await this.getAllProductPackages();
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("FEE.TITLE"),
        route: "/configure/fees",
      },
      {
        title: `${this.$i18n.t(
          "FEE." + this.$route.params.operation.toUpperCase()
        )}`,
      },
    ]);
    if (this.$route.params.operation === "edit") {
      this.loadData();
    }
  },
  methods: {
    ...mapActions([
      "getListFees",
      "getFee",
      "getCourses",
      "getRooms",
      "getAddons",
      "getAllProductPackages",
      "storeFee",
      "updateFee",
      "deleteFee",
    ]),
    setFormData(data) {
      this.formData = data;
    },
    setFormPricing(data) {
      this.formPricing = data;
    },
    async duplicate() {
      const result = await this.validateFields();
      if (result) {
        if (this.$route.params.operation === "edit") {
          let form = this.formData;
          const pricing = this.formPricing;
          const selectedProducts = this.formList;
          form.name = form.name + " (Copy)";
          this.storeFee({ form, pricing, selectedProducts }).then(response => {
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
            this.$router.push({
              name: "fees-form-edit",
              params: {
                operation: "edit",
                index: response.data.feeId,
              },
            });
          });
        }
      } else {
        this.$bvToast.toast(
          this.$i18n.t("PRODUCTS_ADDONS.PLEASE_VERIFY_FIELDS"),
          {
            title: "Error",
            variant: "danger",
            solid: true,
          }
        );
        return false;
      }
    },
    async validateFields() {
      const resultFormFee = await this.$refs.formFee.validateForm();
      const resultFormPrice = await this.$refs.feePricing.validateForm();
      return resultFormFee && resultFormPrice;
    },
    async saveData() {
      const result = await this.validateFields();
      if (result) {
        const form = this.formData;
        const pricing = this.formPricing;
        const selectedProducts = this.formList;
        if (this.$route.params.operation !== "edit") {
          this.storeFee({ form, pricing, selectedProducts }).then(response => {
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
            this.id = response.data.feeId;
            this.$router.push({
              name: "fees-form-edit",
              params: {
                operation: "edit",
                index: response.data.feeId,
              },
            });
            this.$store.dispatch(SET_BREADCRUMB, [
              {
                title: this.$i18n.t("FEE.TITLE"),
                route: "/configure/fees",
              },
              {
                title: `${this.$i18n.t("FEE.EDIT")}`,
              },
            ]);
          });
        } else {
          const id = this.$route.params.index;
          this.updateFee({ id, form, pricing, selectedProducts }).then(() => {
            this.$bvToast.toast(this.$t("MESSAGE.UPDATE.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.UPDATE.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
          });
        }
      } else {
        this.$bvToast.toast(
          this.$i18n.t("PRODUCTS_ADDONS.PLEASE_VERIFY_FIELDS"),
          {
            title: "Error",
            variant: "danger",
            solid: true,
          }
        );
        return false;
      }
    },
    deleteData() {
      this.deleteFee(this.$route.params.index).then(() => {
        this.$bvToast.toast(this.$t("MESSAGE.DELETE.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.DELETE.TITLE_NEW"),
          variant: "success",
          solid: true,
        });
        this.$router.push({ name: "fees" });
      });
    },
    loadData() {
      const feeValue = JSON.parse(JSON.stringify(this.fee));
      this.id = feeValue.feeId;
      this.formData.name = feeValue.name;
      this.formData.feeTypeId = feeValue.feeTypeId;
      this.formData.applicableTo = feeValue.applicableTo;
      this.formData.description = feeValue.description;
      this.formData.feeAlternativeId = feeValue.feeAlternativeId;

      const arrList = [];

      feeValue.product_fee?.map(item => {
        arrList.push({ id: item.productId, name: item.name });
      });

      switch (feeValue.applicableTo) {
        case 1:
          this.formList.courses = arrList;
          break;
        case 2:
          this.formList.rooms = arrList;
          break;
        case 3:
          this.formList.addons = arrList;
          break;
        case 4:
          this.formList.packages = arrList;
          break;

        default:
          break;
      }
      this.formPricing = [
        {
          price: feeValue.price,
          repeat: feeValue.frecuencyType,
          type_duration: feeValue.repeatDurationTypeId,
          maxRepeat: feeValue.maxRepeat,
        },
      ];
    },
    btnCancel() {
      this.$router.push("/configure/fees");
    },
    btnDelete() {
      this.isShowDialogDelete = true;
    },
    async fetchData() {
      if (this.$route.params.operation === "edit") {
        await this.getFee(this.$route.params.index).then(() => {
          this.loadData();
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "courses",
      "rooms",
      "addons",
      "productPackages",
      "fee",
      "getFees",
    ]),
    isEditForm: function() {
      return this.$route.params.operation === "edit";
    },
  },
  watch: {
    $route: "fetchData",
  },
};
</script>
<style scope>
.size-medimum {
  font-size: 1.05rem;
}
</style>
